import React, { useEffect } from "react"
import SEO from "../components/seo"

function GamePage({ pageContext }) {
  return (
    <div>
      <SEO
        title={`Playing ${pageContext.data.name} | Gangs of Gamer Free online HTML 5 games`}
      />
      <iframe
        title="new"
        style={{ width: "100vw", height: "100vh" }}
        src={pageContext.data.gamePath}
        frameBorder="0"
      ></iframe>
    </div>
  )
}

export default GamePage
